export const MEDIA_UPLOAD_SIZE = 1 * 1024 * 1024

export const PERMIT: AdminPermissions = {
  administrator: {
    displayName: '管理者',
    permissions: []
  },
  editor: {
    displayName: '編集者',
    permissions: []
  },
  author: {
    displayName: '閲覧者',
    permissions: []
  }
}

export const PagePermit: AdminItem[] = [
  { name: 'ダッシュボード', path: 'dashboard', icon: '', level: 1, role: ['administrator', 'author', 'editor'], show: false },
  { name: '線画', path: 'sengas', icon: '', level: 1, role: ['administrator', 'editor'], show: true },
  { name: 'Book', path: 'books', icon: '', level: 1, role: ['editor'], show: false },
  { name: 'メンバー', path: 'members', icon: '', level: 1, role: ['administrator'], show: true },
  { name: 'ユーザー', path: 'users', icon: '', level: 1, role: ['administrator', 'editor'], show: true },
  { name: 'プラン', path: 'plans', icon: '', level: 1, role: ['administrator', 'editor'], show: false },
  { name: '設定', path: 'setting', icon: 'gear', level: 1, role: ['administrator'], show: true }
]
